@import "../../utils/variables.scss";
.rubrique-liste-articles {
  .rubrique-liste-articles-grid {
    justify-content: center;
    .rubrique-liste-article-grid {
      max-width: 600px;
    }
  }
  & a {
    color:#000;
    text-decoration: none;
    &:hover {
      color:#FFF;
    }
  }
  & .rubrique-liste-article {
    position:relative;
    text-align:center;
    color:#000;
    padding:0;
    margin:2em 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
    min-width:300px;
    & .rubrique-liste-article-titre {
      font-size:35px;
      font-weight:bold;
      text-transform: uppercase;
      line-height: 1;
      @include xl {
        font-size:30px;
      }
      @include sm {
        font-size:35px;
      }
      @include xs {
        font-size:25px;
      }
      &:hover {
        color:#FFF;
      }
    }
    & .rubrique-liste-article-type {
      font-size:30px;
      font-weight:normal;
      text-transform: uppercase;
      line-height: 1;
      @include xl {
        font-size:25px;
      }
      @include sm {
        font-size:30px;
      }
      @include xs {
        font-size:25px;
      }
    }
    & .rubrique-liste-article-image {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      display: none;
      pointer-events: none;
      img {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit: cover;
        mix-blend-mode: multiply;
        filter:grayscale(1) contrast(1.5);
      }
      .image-mask {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color: var(--main-color);
      }
    }
    &:hover .rubrique-liste-article-image{
      display: block;
    }
  }
  .expo-tile {
    width:100%;
    padding-bottom:75%;
    position:relative;
    overflow:visible;
    .expo-tile-inner {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: visible;
    }
  }
  .expo-chooser {
    text-align:center;
    font-size:20px;
    font-weight:bold;
    line-height: 1;
    margin-bottom:5px;
    color:#000;
    cursor:pointer;
    user-select: none;
    .fleche-gauche {
      display:inline-block;
      position:relative;
      top:3px;
      width:20px;
      height:20px;
      background-image: url('../../assets/fleche_gauche_b.svg');
      background-size:contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    .fleche-droite {
      display:inline-block;
      position:relative;
      top:3px;
      width:20px;
      height:20px;
      background-image: url('../../assets/fleche_droite_b.svg');
      background-size:contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    &:hover {
      color:#FFF;
      .fleche-gauche {
        background-image: url('../../assets/fleche_gauche.svg');
      }
      .fleche-droite {
        background-image: url('../../assets/fleche_droite.svg');
      }
    }
    &.choosen {
      font-size:40px;
      color:#FFF;
      cursor:auto;
    }
  }
}
