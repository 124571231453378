@import "../../utils/variables.scss";
.topbar {
  position:sticky;
  z-index:102;
  top:0;
  background-color: var(--main-color);
  opacity:1;
  transition: opacity 0.5s;
  pointer-events: none;
  .topbar-inner{
    z-index:2;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:250px;
    box-sizing: border-box;
    padding:80px 60px;
    @include sm {
      padding:30px 15px;
    }
    transition: background-color 0.5s, height 0.5s, padding 0.5s, top 0.5s;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    .logo-container {
      display:flex;
      flex-direction: column;
      justify-content: center;
    }
    .main-menu-container {
      background-color:#FFF0;
      height:50px;
      transition:top 0.5s, background-color 0.5s;
      display:flex;
      flex-direction: column;
      justify-content: center;
    }
    .lang-switch-container {
      height:50px;
      display:flex;
      justify-content: center;
      align-items: center;
      pointer-events:visible;
    }
    .burger-container {
      height:40px;
      width:40px;
      margin-left:15px;
      background-image:url('../../assets/burger.svg');
      background-position: center;
      background-size: contain;
      cursor: pointer;
    }
    &.scrolled {
      padding:0 60px;
      @include xs {
        padding:0 15px;
      }
      transition: background-color 1s, height 0.5s, padding 0.5s, top 0.5s;
      background-color: var(--main-color-light);
      height:50px;
      .main-menu-container {
        top:0;
        height:50px;
      }
      .logo-container {
        top:0;
        height:50px;
      }
    }
  }
  .main-menu {
    opacity:1;
    pointer-events:visible;
  }
  &.hide .main-menu {
    opacity:0;
    pointer-events:none;
  }
  &.hide .actu-btn {
    opacity:0;
    pointer-events:none;
  }
  &.hide .menu-mobile .main-menu {
    opacity:1;
    pointer-events:all;
  }
  &.active .main-menu-container {
    background-color:var(--main-color);
  }
  &.folded .topbar-inner {
    height:0px;
  }
}
.menu-mobile {
  position:fixed;
  top:0;
  left:-10px;
  height:100%;
  width:calc(100% - 70px);
  max-width:350px;
  transform: translate3d(-100%,0,0);
  transition: transform 0.5s;
  background-color: var(--main-color-light);
  z-index:103;
  box-shadow: 0 0 10px #0002;
  overflow: hidden;
  &.on {
    transform: translate3d(10px,0,0);
  }
}
