@import "../../../utils/variables.scss";
.ariane {
  position:relative;
  height:100%;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-grow: 1;
  &.local {
    height:auto;
  }
  flex-wrap:wrap;
  span {
    white-space: nowrap;
  }
  font-size:16px;
  line-height:1;
  @include lg {
    font-size:12px;
  }
  font-weight:500;
  color:#000;
  text-transform:uppercase;
  & a {
    color:#000;
    text-decoration: none;
    &:hover {
      color:#FFF;
    }
  }
}
