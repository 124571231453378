@import "../../utils/variables.scss";
.recherche {
  position:relative;
  font-size:16px;
  line-height:16px;
  @include lg {
    font-size:12px;
  }
  padding-left:30px;
  font-weight:500;
  text-transform:uppercase;
  letter-spacing: 0.2em;
  cursor: pointer;
  &>span {
    letter-spacing: 0em;
  }
  height: 100%;
  display:flex;
  justify-content: flex-end;
  align-items: center;
}
.recherche-results {
  opacity:0;
  transition:opacity 0.5s;
  pointer-events: none;
  &.on {
    opacity:1;
    pointer-events:all;
  }
  .recherche-mask0 {
    position:fixed;
    z-index:101;
    height:100%;
    min-height:500px;
    width:100%;
    top:0;
    left:0;
    background-color: #FFF;
  }
  .recherche-mask {
    position:fixed;
    z-index:101;
    height:100%;
    min-height:500px;
    width:100%;
    top:0;
    left:0;
    background-color:#000;
    background-size: cover;
    background-position: center;
    filter:grayscale(1) contrast(1.5);
  }
  .recherche-mask2 {
    position:fixed;
    z-index:101;
    height:100%;
    min-height:500px;
    width:100%;
    top:0;
    left:0;
    background-color:#000;
    background-size: cover;
    background-position: center;
    transition:opacity 0s linear 0.5s;
    background-color: var(--main-color);
    mix-blend-mode:multiply;
  }
  .recherche-overlay {
    position:fixed;
    display:flex;
    z-index:103;
    height:100%;
    min-height: 500px;
    width:100%;
    top:0;
    left:0;
    box-sizing: border-box;
    padding: 100px 0;
    transition:opacity 0.5s;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    & .recherche-close {
      position:absolute;
      top:15px;
      right:15px;
      width:80px;
      height:80px;
      cursor: pointer;
      background-image: url('./croix_blanc.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    .main-input>input {
      background:none;
      border:none;
      color:#FFF;
      font-size:100px;
      @include lg {
        font-size:80px;
      }
      @include xs {
        font-size:60px;
      }
      font-style: italic;
      font-weight:bold;
      width:100%;
      text-align: center;
      outline: none;
      margin-bottom:30px;
    }
    .results {
      color:#FFF;
      font-size:50px;
      @include lg {
        font-size:40px;
      }
      font-weight:bold;
      .ariane {
        color:#FFF;
        font-size:0.3em;
        flex-wrap:wrap;
        span {
          white-space: nowrap;
        }
      }
      a{
        opacity:0.8;
        color:#FFF;
        text-decoration: none;
        &:hover {
          opacity:1;
        }
      }
    }
  }
}
