@import "../../utils/variables.scss";
.logo {
  font-size:30px;
  @include xl {
    font-size:25px;
  }
  @include lg {
    font-size:22px;
  }
  font-weight:bold;
  text-align:center;
  line-height:1;
  pointer-events: visible;
  .nom {
    font-size:45px;
    @include xl {
      font-size:30px;
    }
    @include lg {
      font-size:25px;
    }
    white-space: nowrap;
    text-transform:uppercase;
  }
  .soustitre {
    text-transform:uppercase;
    font-weight:normal;
    white-space: pre-line;
  }
  .pathname {
    font-size:30px;
    @include xl {
      font-size:25px;
    }
    @include lg {
      font-size:22px;
    }
    font-weight:normal;
    text-transform: uppercase;
  }
  a {
    color:#000;
    text-decoration:none;
  }
  .scrolled & {
    .soustitre, .pathname {
      display:none;
    }
  }
}
