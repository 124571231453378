@import "../../utils/variables.scss";
.rubrique {
  &.exact {
    margin:0 50px;
    @include xs {
      margin:0 30px;
    }
    min-height:calc( 100vh - #{2*$menuHeight} );
    @include sm {
      min-height:calc( 100vh - #{2*$menuHeightSm} - 100px );
    }
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom:100px;
  }
  .rubrique-texte{
    color:#000;
    font-size:20px;
    margin:50px 0;
    a {
      color:#FFF;
      text-decoration: none;
    }
    img {
      max-width:100%;
    }
  }
  h3.rubrique-titre {
    text-align:center;
    color:#000;
    font-size:120px;
    @include lg {
      font-size:100px;
    }
    font-weight:bold;
    margin-top:0;
    margin-bottom:0.2em;
  }
}
