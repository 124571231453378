@import "../../utils/variables.scss";
.article-mosaique {
  position:relative;
  width:100%;
  .article-mosaique-inner {
    display:flex;
    flex-wrap: wrap;
    justify-content:flex-end;
  }
  height:545px;
  overflow:hidden;
  .article-images-wrapper {
    position: relative;
    margin:7.5px 7.5px;
    height:250px;
    max-height:15vw;
    .article-images {
      position: relative;
      transform-origin: center;
      img {
        height:250px;
        max-height:15vw;
        display: block;
      }
    }
  }
  &.wide {
    height:unset;
    .article-mosaique-inner {
      justify-content:space-evenly;
    }
    .article-images {
      img {
        max-height:30vw;
      }
    }
    .article-images-wrapper {
      max-height:30vw;
    }
  }
  .article-images img, .article-images-float img {
    cursor:pointer;
  }
}
.article-images-credits {
  text-align:right;
  padding:0 10px;
}
