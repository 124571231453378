@import "../../utils/variables.scss";
.menu-articles{
  display:flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  .menu-articles-item {
    margin: 2em 30px;
    min-width:300px;
    .rubrique-menu-articles {
      text-align:center;
      color:#000;
      padding:0;
      margin:15px 0;
      font-size:30px;
      line-height:1;
      font-weight:bold;
      height:100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      a {
        color:#000;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          color:#FFF;
        }
      }
    }
  }
}
