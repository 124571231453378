.carte {
  width:100%;
  &.move-right {
    position:absolute;
    top:0;
    left:100%;
  }
  padding-bottom:56.25%;
  position: relative;
  .carte-inner {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    .carte-map {
      position:relative;
      z-index:0;
      width:100%;
      height:100%;
      mix-blend-mode: luminosity;
      & h3 {
        font-weight:bold;
        margin:5px 0;
        border-top: 1px solid #0002;
      }
    }
  }
}
