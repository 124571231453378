.bloc-document {
  margin:30px 0;
  border-radius:8px;
  text-align:left;
  font-size:16px;
  text-align: center;
  font-weight:500;
  h3 {
    margin:30px 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight:500;
  }
  a  {
    font-size: 16px;
    &:visited, &:hover, &:active {
    }
  }
  .document-container {
    margin:30px 0;
  }
  p:first-child {
    margin-top:0;
  }
  .bloc-document-star{
    width:100%;
    display: flex;
    justify-content: center;
    span {
      display: inline-block;
      width:30px;
      height:30px;
      background-image: url('./croix_noir.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
