@import "../../utils/variables.scss";
.menu-rubriques{
  display:flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  .menu-rubriques-item {
    margin: 0 30px;
    min-height:100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .rubrique-menu-rubriques {
      text-align:center;
      color:#000;
      padding:0;
      margin:15px 0;
      height:100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      a {
        color:#000;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          color:#FFF;
        }
      }
      & .rubrique-menu-rubrique-titre {
        font-size:30px;
        font-weight:bold;
        line-height: 1;
        margin-bottom:5px;
      }
      & .rubrique-sousmenu {
        font-size:30px;
        font-weight:normal;
        line-height: 1;
        margin-bottom:5px;
      }
    }
  }
}
