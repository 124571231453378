.bloc {
  width:100%;
  position:relative;
  &.move-right {
    position:absolute;
    top:0;
    left:calc(100% + 8px);
    width:calc(100% - 16px);
  }
}
