@import "../../utils/variables.scss";
.actualites.swiper-top-container {
  margin-top:-$menuHeight;
  @include sm {
    margin-top:-$menuHeightSm;
  }
  width:100%;
  height:101vh;
  min-height:550px;
  @include xs {
    min-height:450px;
  }
  padding-bottom: 0;
  position:relative;
  .swiper-top-container-inner {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    .swiper-container {
      width:100%;
      height:100%;
      .swiper-wrapper {
        width:100%;
        height:100%;
        .swiper-slide {
          width:100%;
          height:100%;
          .actualite-image {
            width:100%;
            height:100%;
            background-color: var(--main-color);
          }
        }
      }
    }
    img {
      width:100%;
      height:100%;
      mix-blend-mode: screen;
      filter:grayscale(1) contrast(1.5);
    }
    .hover img {
      mix-blend-mode: multiply;
    }
    .actualite-desc {
      color:#000;
      &.hover  {
        color:#FFF;
      }
      position:absolute;
      bottom:calc( 1% + 15px) ;
      @include xs {
        bottom:calc( 1% + 30px) ;
      }
      left:0;
      width:100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      text-align:center;
      font-size:80px;
      @include lg {
        font-size:40px;
      }
      font-weight:bold;
      margin-top:0;
      margin-bottom:15px;
      h3.article-titre {
        text-align:center;
        font-size:80px;
        line-height:0.8;
        @include xl {
          font-size:70px;
        }
        @include lg {
          font-size:60px;
        }
        @include md {
          font-size:55px;
        }
        @include sm {
          font-size:40px;
        }
        @include xs {
          font-size:30px;
        }
        padding:0 15px;
        font-weight:bold;
        margin:0;
      }
      h3.article-artiste {
        text-align:center;
        font-size:80px;
        line-height:1;
        @include xl {
          font-size:70px;
        }
        @include lg {
          font-size:60px;
        }
        @include md {
          font-size:55px;
        }
        @include sm {
          font-size:40px;
        }
        @include xs {
          font-size:30px;
        }
        padding:0 15px;
        font-weight:bold;
        margin:15px;
      }
      .article-chapo {
        font-size:26px;
        font-weight:500;
        margin:0;
        @include xl {
          font-size:24px;
        }
        @include lg {
          font-size:22px;
        }
        @include md {
          font-size:20px;
        }
        @include sm {
          font-size:18px;
        }
        *:first-child {
          margin-top:0;
        }
        *:last-child {
          margin-bottom:0;
        }
      }
      .article-dates {
        font-size:70px;
        font-weight:500;
        line-height: 1;
        margin:0;
        @include xl {
          font-size:65px;
        }
        @include lg {
          font-size:60px;
        }
        @include md {
          font-size:55px;
        }
        @include sm {
          font-size:50px;
        }
      }
      .article-lieu {
        font-size:40px;
        letter-spacing: 0.15em;
        font-weight:500;
        margin-bottom:30px;
        padding:0 15px;
        @include xl {
          font-size:30px;
        }
        @include lg {
          font-size:25px;
        }
        @include md {
          font-size:20px;
        }
        @include md {
          letter-spacing: 0.05em;
          font-size:18px;
        }
      }
    }
  }
  .son {
    position:fixed;
    bottom:9px;
    left:50px;
    width:calc( 100% - 100px );
    z-index:2000;
  }
}
