.myimg {
  opacity:0;
  transition: opacity 0.5s;
  &.loaded {
    opacity:1;
  }
  &.img-contain {
    object-fit: contain;
    object-position: center;
    &.left {
      object-position: left center;
    }
  }
  &.img-cover {
    object-fit: cover;
    object-position: center;
  }
}
