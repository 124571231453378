@import "../../utils/variables.scss";
.son {
  pointer-events: none;
  .son-groupe {
      margin-left:10px;
      font-size:16px;
  }
  .son-titre-small {
    position:absolute;
    opacity:1;
    top:20px;
    right:0;
    font-weight:800;
    font-size:18px;
    margin-left:50px;
    line-height: 18px;
    text-transform:uppercase;
    background-color: var(--main-color);
    padding:2px 5px;
    border-radius: 4px;
    & .player-close {
      pointer-events: all;
      display:inline-block;
      position:relative;
      margin-left:10px;
      bottom:0;
      left:0;
      width:1em;
      height:1em;
      cursor: pointer;
      background-image: url('./croix_noir.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .son-controls {
      position:relative;
      height:50px;
      .mask {
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        height:50px;
        background: linear-gradient(#0000,var(--main-color));
        opacity:0;
        transition:opacity 0.5s;
      }
  }
  .son-progress {
      position:absolute;
      width:100%;
      top:-10px;
      transition:top 0.5s;
      pointer-events: all;
  }
  .play {
      position:absolute;
      opacity:1;
      top:10px;
      left:0;
      cursor:pointer;
      transition: opacity 0.2s, left 0.5s;
      width:30px;
      height:30px;
      pointer-events: all;
      background-image: url('../../assets/play.svg');
      background-size:contain;
      background-repeat: no-repeat;
      background-position: center;
  }
  .next {
    position:absolute;
    opacity:0.2;
    top:10px;
    opacity:0;
    left:23px;
    pointer-events: none;
    cursor:pointer;
    transition:opacity 0.2s;
    width:30px;
    height:30px;
    background-color: green;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: all;
  }
  .pause {
    position:absolute;
    top:10px;
    left:0;
    opacity:0;
    pointer-events: none;
    cursor:pointer;
    transition:opacity 0.2s, left 0.5s;
    width:30px;
    height:30px;
    background-image: url('../../assets/pause.svg');
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &.playing {
    .play {
      opacity:0;
      pointer-events: none;
    }
    .next {
      pointer-events: all;
      opacity:1;
    }
    .pause {
      opacity:1;
      pointer-events: all;
    }
  }
}
.mini {
  .son-progress {
    top:40px;
  }
  .play {
    left:-40px;
  }
  .pause {
    left:-40px;
  }
  @include xs {
    .play {
      left:0px;
    }
    .pause {
      left:0px;
    }
  }
  .son-controls{
    .mask {
      opacity:1;
    }
  }
}
.son-carre {
  width:100%;
  position:relative;
  padding-bottom:100%;
  .son-carre-inner {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:var(--main-color);
    display:flex;
    justify-content: center;
    align-items: center;
    img {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      object-fit:cover;
      object-position:center;
      mix-blend-mode: screen;
      filter:grayscale(1) contrast(1.5);
    }
    .rond-background {
      width:57.5%;
      height:57.5%;
      border-radius: 50%;
      background-color: var(--main-color);
      opacity:0.8;
      cursor:pointer;
      pointer-events: all;
    }
    .son-progress {
      position:absolute;
      width:100%;
      left:0;
      top:unset;
      bottom:-5px;
    }
    .play {
      position:absolute;
      top:35%;
      left:38%;
      opacity:1;
      pointer-events: all;
      cursor:pointer;
      transition: opacity 0.2s;
      width:30%;
      height:30%;
      background-image: url('../../assets/play.svg');
      background-size:contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    .pause {
      position:absolute;
      top:35%;
      left:35%;
      opacity:0;
      pointer-events: none;
      cursor:pointer;
      transition: opacity 0.2s;
      width:30%;
      height:30%;
      background-image: url('../../assets/pause.svg');
      background-size:contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &.playing {
    .son-carre-inner {
      .play {
        opacity:0;
        pointer-events: none;
      }
      .next {
        pointer-events: all;
        opacity:1;
      }
      .pause {
        opacity:1;
        pointer-events: all;
      }
    }
  }
}
