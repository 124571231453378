@import "../../../utils/variables.scss";
.gallery {
  position:fixed;
  z-index:104;
  height:100%;
  width:100%;
  top:0;
  left:0;
  background-color:var(--main-color);
  display:none;
  &.on {
    display:block;
    & img {
      height:100%;
      width:100%;
      cursor: pointer;
    }
    & .gallery-close {
      position:absolute;
      top:15px;
      right:15px;
      width:80px;
      height:80px;
      @include xs {
        width:40px;
        height:40px;
      }
      cursor: pointer;
      background-image: url('./croix_blanc.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    & .gallery-credits {
      position:absolute;
      bottom:5px;
      left:10px;
      color:#FFF;
      text-shadow: 0 0 10px #0008;
    }
  }
}
