.bloc-image {
  width:100%;
  position:relative;
  &.move-right {
    position:absolute;
    top:0;
    left:100%;
    width:100%;
  }
}
