@import "../../utils/variables.scss";
.lang-switch {
    white-space: nowrap;
    .xs & {
        bottom:10px;
        right:5px;
    }
    & span {
      display:inline-block;
      position:relative;
      bottom:2px;
      text-align:center;
      width:2em;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 1em;
      font-weight: 800;
      cursor: pointer;
      opacity:0.5;
      &.active {
        opacity:1;
      }
      &:hover {
        color:#FFF;
      }
    }
}
