@import "../../utils/variables.scss";
.article {
  opacity:0;
  &.ready {
    opacity:1;
  }
  margin-top:-$menuHeight;
  @include sm {
    margin-top:-$menuHeightSm;
  }
  a {
    color:#000;
    text-decoration: none;
    &:hover {
      color:#FFF;
    }
  }
  h3.article-titre {
    text-align:center;
    color:#000;
    font-size:120px;
    line-height:0.8;
    @include xl {
      font-size:110px;
    }
    @include lg {
      font-size:100px;
    }
    font-weight:bold;
    margin:0;
  }
  h3.article-generique-titre {
    text-align:left;
    color:#000;
    font-size:120px;
    line-height:0.8;
    margin:60px 0;
    @include xl {
      font-size:110px;
    }
    @include lg {
      font-size:100px;
    }
    @include md {
      font-size:80px;
      margin-bottom: 20px;
    }
    @include sm {
      font-size:70px;
      margin-bottom: 20px;
    }
    @include xs {
      font-size:50px;
      margin-top: 40px;
      margin-bottom: 0;
    }
    font-weight:bold;
  }
  h3.article-artiste {
    text-align:center;
    color:#000;
    line-height:0.8;
    font-size:120px;
    @include lg {
      font-size:100px;
    }
    font-weight:bold;
    margin-top:0.2em;
    margin-bottom:15px;
  }
  h3.article-generique-artiste {
    color:#000;
    line-height:0.8;
    font-size:50px;
    font-weight:bold;
    margin-top:0.2em;
    margin-bottom:15px;
    @include xl {
      font-size:50px;
    }
    @include lg {
      font-size:50px;
    }
    @include md {
      font-size:50px;
      margin-top:1em;
      margin-bottom:0.2em;
    }
    @include sm {
      font-size:50px;
    }
    @include xs {
      font-size:30px;
      margin-top:1em;
      margin-bottom:0.2em;
    }
  }
  .article-intro {
    text-align:center;
    color:#000;
    font-size:25px;
    @include lg {
      font-size:25px;
    }
    font-weight: bold;
  }
  .article-texte {
    text-align:left;
    color:#000;
    font-size:20px;
    p:first-child {
      margin-top:0;
    }
    margin:0;
  }
  .article-visuel-preview {
    position:relative;
    background-color:#0000;
    height:100vh;
    transition:top 0.5s,background-color 0.5s;
    .swiper-top-container {
      width:100%;
      height:100%;
      padding-bottom:0;
    }
    img {

    }
  }
  &.on-screen .article-visuel-preview {
    background-color: #000;
  }
  .view-less-btn, .view-more-btn{
    display:none;
  }
  .article-content {
    margin-top:-50px;
    padding-top:50px;
    .article-content-header {
      position:relative;
      z-index:10;
      opacity:1;
      transform:translate3d(0,0,0);
      transition: transform 0.5s, opacity 0.5s;
      padding: 0 5px;
    }
    h3.article-titre {
      transition: color 0.5s, text-shadow 0.5s;
      padding-top:30px;
      text-shadow: 0 0 0 #0008;
      color:#000;
    }
    h3.article-artiste {
      text-shadow: 0 0 0 #0008;
      color:#000;
      transition:color 0.5s;
    }
  }
  &.on-screen .article-content {
    .article-content-header {
      transform:translate3d(0,calc(-100% - 5vh),0);
      opacity:0;
    }
  }
  &.on-screen.overlay .article-content {
    .article-content-header {
      transform:translate3d(0,calc(-100% - 5vh),0);
      opacity:1;
    }
  }
}
.article-podcast {
  padding:75px;
  @include xl {
    padding:50px;
  }
  @include xs {
    padding:15px;
  }
  h3.article-titre {
    text-transform: uppercase;
    font-style: italic;
    margin-top:50px;
    margin-bottom:100px;
    @include xl {
      font-size:110px;
    }
    @include lg {
      margin-bottom:70px;
      font-size:100px;
    }
    @include sm {
      font-size:80px;
    }
    @include xs {
      margin-bottom:50px;
      font-size:50px;
    }
  }
  .podcast-container {
    position:relative;
    display:flex;
    justify-content: space-between;
    &.podcast-container-xs {
      display:block;
    }
    .podcast-col-1 {
      width:21%;
      @include xl {
        width:18%;
      }
    }
    .podcast-col-2 {
      width:calc( 79% - 75px);
      @include xl {
        width:calc( 82% - 50px);
      }
      @include lg {
        width:calc( 82% - 30px);
      }
      &>div {
        position:relative;
        display:flex;
        justify-content: space-between;
        .podcast-col-2-1 {
          width:calc( 72% - 75px);
          @include xl {
            width:calc( 76% - 50px);
          }
          @include lg {
            width:calc( 76% - 30px);
          }
        }
        .podcast-col-2-2 {
          width:28%;
          @include xl {
            width:24%;
          }
        }
      }
    }
    .article-texte {
      margin-top:50px;
      font-size:35px;
      font-weight:800;
      text-align:center;
      line-height:1;
      @include xl {
        font-size:30px;
      }
      @include lg {
        font-size:22px;
      }
      @include sm {
        font-size:20px;
      }
      @include xs {
        margin-top:30px;
        font-size:18px;
      }
    }
    .podcast-image-container {
      width:100%;
      position:relative;
      border: 6px solid #FFF8;
      .podcast-image-inner{
        .swiper-top-container {
          background-color:var(--main-color);
          padding-bottom:75%;
        }
      }
    }
    .podcast-container-fleches {
      display:flex;
      align-items: center;
    }
    .fleches-container{
      width:100%;
      position:relative;
      padding-bottom:50%;
      .fleche-gauche {
        position:absolute;
        top:0;
        left:0;
        width:32%;
        height:100%;
        background-image: url('../../assets/fleche_gauche.svg');
        background-size:contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity:0.2;
        &.active {
          opacity:1;
          cursor:pointer;
        }
      }
      .fleche-droite {
        position:absolute;
        top:0;
        right:0;
        width:32%;
        height:100%;
        background-image: url('../../assets/fleche_droite.svg');
        background-size:contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity:0.2;
        &.active {
          opacity:1;
          cursor:pointer;
        }
      }
    }
    .intervenants {
      .intervenant-nom {
        font-size:20px;
        font-weight:800;
        text-decoration: underline;
        margin-top:20px;
        @include xl {
          font-size:20px;
        }
        @include lg {
          font-size:18px;
        }
      }
      .intervenant-bio {
        font-size:20px;
        font-weight:300;
        @include xl {
          font-size:20px;
        }
        @include lg {
          font-size:18px;
        }
      }
    }
    .generiques {
      margin-top:40px;
      .generique-fonction {
        margin-top:10px;
        font-size:20px;
        font-weight:800;
        text-decoration: underline;
        @include xl {
          font-size:20px;
        }
        @include lg {
          font-size:18px;
        }
      }
      .generique-nom {
        font-size:20px;
        font-weight:300;
        white-space: pre-line;
        @include xl {
          font-size:20px;
        }
        @include lg {
          font-size:18px;
        }
      }
    }
    .ressources {
      margin-top:50px;
      .ressource-surtitre {
        margin-top:50px;
        font-size:20px;
        font-weight:300;
        @include xl {
          font-size:20px;
        }
        @include lg {
          font-size:18px;
        }
      }
      .ressource-titre {
        font-size:35px;
        font-weight:800;
        text-transform: uppercase;
        line-height:1;
        @include xl {
          font-size:30px;
        }
        @include lg {
          font-size:22px;
        }
        @include sm {
          font-size:20px;
        }
        @include xs {
          font-size:16px;
        }
      }
    }
  }
}
.article-exposition {
  .article-content {
    @include md {
      padding: 0 26px;
      padding-top:50px;
    }
    @include xs {
      padding: 0 0;
      padding-top:50px;
    }
  }
  .article-texte {
    position: relative;
    margin-top:0;
    max-height:600px;
    overflow: hidden;
    a {
      color:#FFF;
      text-decoration: none;
    }
    p {
      text-overflow: ellipsis;
    }
    .view-more {
      position: absolute;
      bottom:0;
      height:50px;
      width:100%;
      left:0;
      background: linear-gradient(#0000, var(--main-color));
      display:none;
    }
  }
  &.need-more {
    .view-more {
      display: block;
      position: absolute;
      bottom:0;
      height:50px;
      width:100%;
      left:0;
      background: linear-gradient(#0000, var(--main-color));
    }
    .view-less-btn {
      display:none;
    }
    .view-more-btn {
      display:block;
    }
    .view-less-btn, .view-more-btn{
      padding:10px 0;
      font-weight:bold;
      span {
        cursor:pointer;
        &:hover {
          color:#FFF;
        }
      }
    }
  }
  &.need-more.more {
    .view-less-btn {
      display:block;
    }
    .article-texte {
      max-height:none;
      .view-more {
        display:none;
      }
    }
    .view-more-btn {
      display:none;
    }
  }
  .article-texte *:first-child {
    margin-top:0;
  }
  h3.article-titre {
    text-transform: uppercase;
    @include xl {
      font-size:90px;
    }
    @include lg {
      font-size:70px;
    }
    @include sm {
      font-size:50px;
    }
    @include xs {
      font-size:25px;
    }
  }
  h3.article-artiste {
    text-transform: uppercase;
    @include xl {
      font-size:90px;
    }
    @include lg {
      font-size:70px;
    }
    @include sm {
      font-size:50px;
    }
    @include xs {
      font-size:25px;
    }
  }
  .article-intro {
    @include xl {
      font-size:30px;
    }
    @include lg {
      font-size:22px;
    }
    @include sm {
      font-size:20px;
    }
    @include xs {
      font-size:16px;
    }
  }
  .article-texte {
    @include lg {
      font-size:18px;
    }
    @include md {
      max-height:unset;
    }
  }
  &.overlay .article-visuel-preview .myimg.img-contain {
    object-fit: cover;
  }
  .article-expo-droite {
    width:600px;
    max-width:100%;
    @include md {
      width:100%;
    }
  }
}
