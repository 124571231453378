@import "../../utils/variables.scss";
.footer {
  position:fixed;
  z-index:102;
  bottom:0;
  width:100%;
  height:80px;
  background-color: var(--main-color);
  opacity:1;
  transition: opacity 0.5s, background-color 0.5s, height 0.5s;
  pointer-events: all;
  display:flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 50px;
  @include xs {
    padding: 0 15px;
  }
  .son {
    position:absolute;
    top:-47px;
    left:50px;
    width:calc( 100% - 100px );
    @include xs {
      left:15px;
      width:calc( 100% - 30px );
    }
    height:50px;
  }
  &.hide {
    opacity:0;
    pointer-events:none;
  }
  &.mini {
    background-color: var(--main-color-light);
    height:30px;
    transition-delay: 250ms;
  }
}
