@import "../../utils/variables.scss";
.podcasts-liste {
  .podcasts-liste-titre {
    font-size:160px;
    font-weight:800;
    font-style: italic;
    text-transform: uppercase;
    text-align:center;
    @include xl {
      font-size:140px;
    }
    @include lg {
      font-size:120px;
    }
    @include md {
      font-size:100px;
    }
    @include sm {
      font-size:80px;
    }
    @include xs {
      font-size:50px;
    }
  }
  .podcasts-liste-annee{
    font-size:150px;
    @include xl {
      font-size:120px;
    }
    @include lg {
      font-size:100px;
    }
    @include md {
      font-size:90px;
    }
    @include sm {
      font-size:70px;
    }
    @include xs {
      font-size:50px;
    }
    font-weight:800;
    font-style: italic;
    text-transform: uppercase;
    text-align:left;
  }
  .podcasts-tiles {
    margin-bottom:100px;
  }
  .podcast-tile {
    position:relative;
    padding-bottom: 100%;
    width:100%;
    .podcast-tile-inner {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background-color:var(--main-color);
      img {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit: cover;
        mix-blend-mode: screen;
        filter:grayscale(1) contrast(1.5);
      }
      .podcast-tile-titre {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        display:flex;
        justify-content: center;
        align-items: center;
        font-size: 43px;
        @include xl {
          font-size:35px;
        }
        @include lg {
          font-size:25px;
        }
        font-weight:800;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
  .podcast-tile:hover {
    .podcast-tile-inner {
      img {
        mix-blend-mode: multiply;
      }
    }
  }
}
