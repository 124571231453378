@import "../../utils/variables.scss";
.main-menu {
  color:#000;
  display:flex;
  font-size:18px;
  font-weight:bold;
  height:100%;
  opacity:1;
  transition: opacity 0.5s;
  overflow-y: auto;
  @include xl {
    font-size:14px;
  }
  @include lg {
    font-size:12px;
  }
  ul {
    list-style: none;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding:0 5px;
    margin:0;
    flex-grow: 1;
    li {
      padding:0 15px;
      @include xl {
        padding:0 10px;
      }
      @include lg {
        padding:0 5px;
      }
    }
    a {
      color:#000;
      text-decoration:none;
      display:inline-block;
      &:hover {
        color:#FFF;
      }
    }
    li.active a {
      color:#FFF;
      &:hover {
        color:#FFF;
      }
    }
    &.mobile {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 15px 0 50px;
      height:100%;
      min-height:500px;
      flex-wrap: nowrap;
      li {
        padding: 5px 0;
        a {
          font-size:24px;
        }
      }
    }
  }
}
