.swiper-top-container {
  width:100%;
  padding-bottom:56.25%;
  position:relative;
  .swiper-top-container-inner {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    .swiper-container {
      width:100%;
      height:100%;
      .swiper-wrapper {
        width:100%;
        height:100%;
        .swiper-slide {
          width:100%;
          height:100%;
          transition:background-color 0.5s;
        }
      }
    }
    img {
      width:100%;
      height:100%;
    }
  }
}
.swiper-top-container.mix {
  background-color:#000;
  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        background-color:var(--main-color);
      }
    }
  }
  img {
    filter:grayscale(1) contrast(1.5);
    mix-blend-mode: screen;
  }
}
.overlay .swiper-top-container {
  img {
    filter:grayscale(1) contrast(1.5);
  }
  .swiper-top-container-inner {
    background-color:var(--main-color);
  }
}
.swiper-top-container {
  .credits {
    position:absolute;
    font-size:14px;
    bottom:0;
    left:0;
    padding:2px 10px;
    color:#333;
    background-color: #fffa;
    border-top-right-radius: 8px;
  }
}
