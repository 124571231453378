@import "utils/variables.scss";
@font-face {
    font-family: 'Akzidenz-Grotesk BQ';
    src: url('./assets/fonts/AkzidenzGroteskBQ-Italic.woff2') format('woff2'),
        url('./assets/fonts/AkzidenzGroteskBQ-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Akzidenz-Grotesk BQ';
    src: url('./assets/fonts/AkzidenzGroteskBQ-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/AkzidenzGroteskBQ-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Akzidenz-Grotesk BQ';
    src: url('./assets/fonts/AkzidenzGroteskBQ-Reg.woff2') format('woff2'),
        url('./assets/fonts/AkzidenzGroteskBQ-Reg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Akzidenz-Grotesk BQ';
    src: url('./assets/fonts/AkzidenzGroteskBQ-Bold.woff2') format('woff2'),
        url('./assets/fonts/AkzidenzGroteskBQ-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Akzidenz-Grotesk BQ';
    src: url('./assets/fonts/AkzidenzGroteskBQ-Medium.woff2') format('woff2'),
        url('./assets/fonts/AkzidenzGroteskBQ-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
body {
  font-family: 'Akzidenz-Grotesk BQ';
  min-height:101vh;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
.fade-enter, .fade-enter-active {
  opacity: 0;
  height:0;
  overflow:hidden;
}
.loaded .fade-enter-active {
  opacity: 1;
  height:auto;
  transition: opacity 200ms;
}
.fade-exit, {
  opacity: 1;
  position:absolute;
  top:0;
  left:0;
  width:100%;
}
.loaded .fade-exit-active {
  opacity:0;
  transition: opacity 200ms;
}

.top-container {
  position:absolute;
  width:100%;
  opacity:0;
  transition: opacity 0.2s;
  &.ready {
    opacity:1;
  }
  .routes-container {
    position:relative;
    padding-top:$menuHeight;
    padding-bottom:$footerHeight;
    @include sm {
      padding-top:$menuHeightSm;
      padding-bottom:$footerHeightSm;
    }
    overflow:hidden;
    & .subroutes {
      position:relative;
      &>div {
        position:relative;
        &>div {
          border-top: 1px solid #0000;
        }
      }
    }
  }
}
.wait {
  position:fixed;
  z-index:1000;
  background-color: var(--main-color);
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  opacity:1;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    opacity:0;
    transition: opacity 0.5s;
    &.on {
      opacity:1;
    }
  }
  .enter {
    cursor:pointer;
    opacity:0;
    transition: opacity 0.5s;
    &.on {
      opacity:1;
    }
  }
  &.ready {
    opacity:0;
    pointer-events: none;
  }
  .wait-logo {
    font-size:7.8vw;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Akzidenz-Grotesk BQ';
    font-weight:800;
    line-height: 0.97;
    color:$black;
    position:relative;
    .ville, .soustitre {
      font-weight:300;
    }
  }
  .status {
    position:absolute;
    bottom:15px;
    left:0;
    width:100%;
    height:30px;
    text-align: center;
    & .loader, & .enter {
      position:absolute;
      bottom:0px;
      left:0;
      width:100%;
      height:100%;
    }
  }
}
.spacer {
  height:30px;
}
.bottom-padding {
  height:200px;
}
.actu-btn {
  background-image: url('./assets/actu.png');
  width:80px;
  @include xl {
    width:60px;
  }
  &.big{
    width:100px;
    margin-bottom: 30px;
  }
  height:50px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  pointer-events: all;
  &:hover {
    filter:invert(1);
  }
  transition:opacity 0.5s;
}
