.pagination {
  .bullet {
    display: inline-block;
    width:30px;
    height:30px;
    border-radius: 30px;
    border:2px solid var(--main-color);
    margin-right:10px;
    cursor:pointer;
    &.active {
      background-color: var(--main-color);
    }
  }
}
